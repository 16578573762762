.outerLabel {
  padding: 0.25rem;
}

.chartArea,
.chartScale {
  position: relative;
}

.chartArea {
  border-right: 1px dashed #97979d;
  padding-top: 1rem;
}
