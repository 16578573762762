.innerBar {
  padding-top: 0.5rem;
  padding-right: 1px;
  position: absolute;
  left: 0;
  display: inline-block;
  top: 50%;
  transform: scaleX(0) translateY(-50%);
  opacity: 0;
  transform-origin: left;
  z-index: 5;
  transition: transform 1s 1s cubic-bezier(0.785, 0.135, 0.15, 0.86);
}

.innerBar.onScreen {
  transform: scaleX(1) translateY(-50%);
  opacity: 1;
}
