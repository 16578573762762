.footerTextContainer {
  padding: 1rem 0;
  margin-top: 2rem;
}

.socialContainer {
  display: flex;
  justify-content: space-between;
  padding: 1rem;
}

.navContainer {
  padding-top: 1rem;
}

.textAndSocialContainer {
  border-bottom: 1px solid #97979d;
}

.navContainer > a {
  padding: 0.5rem 1rem 0.5rem 0;
  display: inline-block;
}

@media only screen and (min-width: 760px) {
  .footerTextContainer {
    display: inline-block;
    max-width: 60%;
    vertical-align: top;
    float: left;
  }

  .textAndSocialContainer::after {
    content: "";
    clear: both;
    display: table;
  }

  .socialContainer {
    display: inline-flex;
    float: right;
    margin-top: 2rem;
  }
}
