/* 1. call it out */
:global(.callItOut) {
  transform: scale(0);
  transform-origin: center;
  transition: transform 1s cubic-bezier(0.23, 1, 0.32, 1);
}

.onScreen :global(.callItOut) {
  transform: scale(1);
}

/* 2. get a mentor */
:global(.mentor1) {
  transform: scale(0.62);
  transform-origin: center;
  transition: transform 1s cubic-bezier(0.23, 1, 0.32, 1);
}

:global(.mentor2) {
  transform: translate(-22%, 22%);
  transition: transform 1s cubic-bezier(0.23, 1, 0.32, 1);
}

/* translate(40%, -2%) */
.onScreen :global(.mentor1) {
  transform: scale(1);
}
.onScreen :global(.mentor2) {
  transform: translate(0%, 0%);
}

/* 3. List your achievements */
:global(.achievements2),
:global(.achievements3),
:global(.achievements4),
:global(.achievements5) {
  opacity: 0;
  transform: translateY(-17%);
}

:global(.achievements3) {
  animation-delay: 0.25s;
}

:global(.achievements4) {
  animation-delay: 0.5s;
}

:global(.achievements5) {
  animation-delay: 0.75s;
}

.onScreen :global(.achievements2),
.onScreen :global(.achievements3),
.onScreen :global(.achievements4),
.onScreen :global(.achievements5) {
  animation-name: appearAndMoveDown;
  animation-duration: 0.25s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}

@keyframes appearAndMoveDown {
  0% {
    opacity: 0;
    transform: translateY(-17%);
  }
  50% {
    opacity: 1;
    transform: translateY(-17%);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

/* 4. Change your language */

:global(.language) {
  transform: translateX(-30%) translateY(-3%);
}

.onScreen :global(.language) {
  animation: moveDownAndAcross 1s forwards cubic-bezier(0.23, 1, 0.32, 1);
}

@keyframes moveDownAndAcross {
  0% {
    transform: translateX(-30%) translateY(-3%);
  }
  90% {
    transform: translateX(0%) translateY(-3%);
  }
  100% {
    transform: translateX(0%) translateY(0%);
  }
}

/* 5. Assess your expectations */

:global(.expectations) {
  transform: translate(-46%, -44%);
  transition: transform 1s cubic-bezier(0.23, 1, 0.32, 1);
}

.onScreen :global(.expectations) {
  transform: translate(0%, 0%);
}

@media only screen and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .onScreen :global(.callItOut),
  .onScreen :global(.mentor1),
  .onScreen :global(.mentor2),
  .onScreen :global(.achievements2),
  .onScreen :global(.achievements3),
  .onScreen :global(.achievements4),
  .onScreen :global(.achievements5),
  .onScreen :global(.language),
  .onScreen :global(.expectations) {
    animation: none !important;
    opacity: 1;
    transform: none;
  }
}
