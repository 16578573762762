.container {
  max-width: 792px;
  min-width: 285px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 1rem;
}

.isSmall {
  max-width: 520px;
}

.noPadding {
  padding: 0;
}

.noMargin {
  margin-left: 0;
  margin-right: 0;
}
