.container {
  border-left: 1px solid #292a36;
  padding-bottom: 1rem;
  padding-left: 1rem;
}

.isCentered {
  border-bottom: 1px solid #292a36;
  border-left: none;
  padding-left: 0;
}
