.content {
  border-left: 1px solid black;
  padding-left: 1rem;
  margin-top: 1rem;
  margin-left: 1rem;
}

.toolTipContainer {
  position: relative;
  padding-left: 2rem;
  padding-top: 2rem;
}

.toolTipArrow {
  position: absolute;
  z-index: 5;
  top: 75%;
  width: 19px;
  height: 54px;
  overflow: hidden;
}
.toolTipContent {
  padding: 0.25rem 1.5rem;
}

.quotation {
  height: 1.5rem;
  line-height: 0.9;
  padding-left: 0.5rem;
  position: relative;
}

.quotation::after {
  content: "";
  background-color: currentColor;
  width: 2000px;
  padding-top: 1.5rem;
  position: absolute;
  top: 0;
  left: -1000px;
}

.quotationMark {
  max-width: 4rem;
  position: relative;
  z-index: 2;
  height: 100%;
  overflow: hidden;
}

.name {
  padding: 0;
}

@media only screen and (min-width: 865px) {
  .container {
    position: absolute;
    left: 0;
    max-width: 250px;
    display: inline-block;
    padding: 0;
  }

  .coloredBar {
    height: 1.5rem;
    position: relative;
  }

  .toolTipContainer {
    padding-left: 0;
    padding-top: 0;
  }

  .toolTipArrow {
    top: 50%;
  }

  .coloredBar::after {
    content: "";
    width: 2000px;
    height: 100%;
    right: 0;
    background-color: currentColor;
    position: absolute;
  }

  .quotation::after {
    display: none;
  }

  .quotation {
    display: inline-block;
    vertical-align: top;
    position: absolute;
    left: -2.5rem;
    margin-top: 1rem;
    padding-right: 0.5rem;
  }

  .content {
    display: inline-block;
    vertical-align: top;
    margin-left: 0rem;
  }

  .name {
    margin-top: 1rem;
  }
}
