.container {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
}

.halfWidth {
  width: 50%;
}

.container::before,
.container::after {
  content: "";
  width: 20%;
  height: 100%;
  top: 0;
  z-index: 1;
  position: absolute;
  border-left: 1px dashed #97979d;
  border-right: 1px dashed #97979d;
}
.container::before {
  left: 20%;
}

.container::after {
  left: 60%;
}

.hasLeftBorder {
  border-left: 1px solid #97979d;
}
