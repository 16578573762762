body {
  margin: 0;
  padding: 0;
  font-family: "Verdana", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

#root {
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
a {
  margin: 0;
  text-decoration: none;
}

/* Makes images responsive by default */
img,
svg {
  display: inline-block;
  vertical-align: bottom;
  max-width: 100%;
}

.extraLargeSpacingPlus {
  margin-bottom: 7rem;
}

.extraLargeSpacing {
  margin-bottom: 6rem;
}

.largeSpacing {
  margin-bottom: 4rem;
}

.smallSpacing {
  margin-bottom: 2rem;
}

@media only screen and (min-width: 760px) {
  .extraLargeSpacingPlus {
    margin-bottom: 6rem;
  }
}

.typography_pageTitle__1tBd- {
  font-size: 33px;
  line-height: 1.3;
  letter-spacing: 0.15rem;
  font-weight: bold;
}

.typography_sectionHeader__2zDOZ,
.typography_relatedSubtitle__35BOB {
  font-size: 20px;
}

.typography_relatedSubtitle__35BOB {
  line-height: 1.2;
  letter-spacing: -0.42px;
}

.typography_relatedDescription__2S0UO {
  line-height: 1.4;
  letter-spacing: -0.23px;
}

.typography_subtitle__28J4O {
  font-size: 18px;
}

.typography_subtitle__28J4O,
.typography_sectionHeader__2zDOZ {
  line-height: 1.6;
}

.typography_body__2uV79,
.typography_listHeader__2EyYY,
.typography_relatedDescription__2S0UO {
  font-size: 16px;
  padding-bottom: 0.5rem;
}

.typography_body__2uV79,
.typography_listHeader__2EyYY {
  line-height: 2;
}

.typography_quote__Sy92p {
  line-height: 1.6;
}

.typography_small__3M_zD {
  font-size: 14px;
}

.typography_chartLabel__3xUnk {
  font-size: 14px;
}

.typography_small__3M_zD {
  line-height: 1.9;
}

.typography_toolTip__3YY6a,
.typography_chartScale__og2fg,
.typography_copyright__33Q76 {
  font-size: 12px;
}

.typography_copyright__33Q76 {
  margin-bottom: 4rem;
  padding: 1rem 0;
  display: inline-block;
}

.typography_hightlightContainer__3N38D {
  white-space: pre-wrap;
  margin-bottom: 0.75rem;
}

.typography_highlight__3yNqd {
  padding-right: 0.75rem;
  padding-bottom: 0;
}

.typography_largeStat__1qPEP {
  font-size: 40px;
  font-weight: bold;
}

.typography_chartScale__og2fg {
  opacity: 1;
}

.typography_isCentered__1DhuS {
  text-align: center;
}

.typography_listHeader__2EyYY,
.typography_subtitle__28J4O {
  font-weight: bold;
}

.typography_underline__3UH2D {
  position: relative;
  padding-bottom: 0.2rem;
  border-bottom: 2px solid #33c100;
  -webkit-transition: padding-bottom 0.3s ease;
  transition: padding-bottom 0.3s ease;
}

.typography_underline__3UH2D:hover {
  padding-bottom: 0;
}

.typography_underlineSubtitle__1HQKs {
  text-decoration: underline;
}

.typography_innerScale__3Di3z {
  text-align: center;
  position: absolute;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  opacity: 0;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}

.typography_outerScale__lFOmV {
  position: absolute;
  right: 0;
  top: 100;
}

@media only screen and (min-width: 760px) {
  .typography_pageTitle__1tBd- {
    font-size: 39px;
  }
  .typography_noPadding__190E3 {
    padding: 0;
    margin-bottom: 0;
  }

  .typography_sectionHeader__2zDOZ {
    font-size: 34px;
    line-height: 1.6;
  }

  .typography_subtitle__28J4O {
    font-size: 20px;
  }

  .typography_body__2uV79,
  .typography_listHeader__2EyYY {
    font-size: 18px;
  }

  .typography_chartLabel__3xUnk {
    font-size: 16px;
  }

  .typography_toolTip__3YY6a,
  .typography_chartScale__og2fg {
    font-size: 14px;
  }

  .typography_largeStat__1qPEP {
    font-size: 48px;
  }

  .typography_innerScale__3Di3z {
    opacity: 1;
  }
}

/* --- WHITE --- */
.colors_txtWhite__3zx-W {
  color: #ffffff;
}
.colors_bgWhite__t2_K1 {
  background-color: #ffffff;
}

/* --- BLACK --- */
.colors_txtBlack__1g7PC {
  color: #292a36;
}
.colors_bgBlack__2O3L- {
  background-color: #292a36;
}

/* --- DARK GREY --- */
.colors_txtDarkGrey__bST7s {
  color: #97979d;
}
.colors_bgDarkGrey__2Dptr {
  background-color: #97979d;
}

/* --- LIGHT GREY --- */
.colors_txtLightGrey__1IZkR {
  color: #6c6d79;
}
.colors_bgLightGrey__3dF5L {
  background-color: #6c6d79;
}

/* --- GREEN --- */
.colors_txtGreen__jmWVv {
  color: #33c100;
}
.colors_bgGreen__16A_r {
  background-color: #33c100 !important;
}

/* --- DARK GREEN --- */
.colors_txtDarkGreen__6X_1p {
  color: #2c8f07;
}
.colors_bgDarkGreen__1OzGE {
  background-color: #2c8f07 !important;
}

/* --- LIGHT BLUE --- */
.colors_txtLightBlue__U7o99 {
  color: #bfe5e5;
}

.colors_bgLightBlue__szIIu {
  background-color: #bfe5e5;
}

/* NOTE: First background color is for IE and Edge, overwritten by second for all other browsers */
.colors_bgLightBlueWithOpacity__1a8bP {
  color: #bfe5e5;
  color: rgba(191, 229, 229, 0.3);
}

.colors_fillLightBlueWithOpacity__2aEfH {
  fill: #bfe5e5;
  opacity: 0.3;
}

/* --- TEAL --- */
.colors_txtTeal__3Xz3S {
  color: #008b8b;
}

.colors_bgTeal__2qAaX {
  background-color: #008b8b;
}

/* --- ORANGE --- */
.colors_txtOrange__2GOuD {
  color: #f06c30;
}

.colors_bgOrange__2ythk {
  background-color: #f06c30;
}

/* --- PINK --- */
.colors_txtPink__2MgTl {
  color: #e2c3d3;
}

.colors_bgPink__2ONdi {
  background-color: #e2c3d3;
}

/* --- MAROON --- */
.colors_txtMaroon__3LUjo {
  color: #8c0e51;
}

.colors_bgMaroon__1IFDk {
  background-color: #8c0e51;
}

.style_container__27YPy {
  max-width: 792px;
  min-width: 285px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 1rem;
}

.style_isSmall__2gw-8 {
  max-width: 520px;
}

.style_noPadding__1SO9J {
  padding: 0;
}

.style_noMargin__2pfpe {
  margin-left: 0;
  margin-right: 0;
}

.style_container__3N68- {
  position: relative;
  min-height: 350px;
  margin-top: -0.25rem;
  margin-bottom: 1rem;
  z-index: -1;
}

.style_svg__H_FJZ {
  position: absolute;
  overflow: visible;
  left: -5350px;
}

.rightLines {
  -webkit-transform: translateY(9%);
          transform: translateY(9%);
  -webkit-animation: style_lines__rky8- 1.25s 0.5s forwards cubic-bezier(0.39, 0.575, 0.565, 1);
          animation: style_lines__rky8- 1.25s 0.5s forwards cubic-bezier(0.39, 0.575, 0.565, 1);
}

.style_headerContainer__2CWeN {
  position: absolute;
  mix-blend-mode: multiply;
  width: 300px;
  top: 50%;
  left: 17%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.style_whiteCircle__3_Ve3 {
  position: absolute;
  background-color: white;
  width: 155px;
  padding-bottom: 155px;
  position: absolute;
  border-radius: 100%;
  z-index: 0;
  top: 30%;
  left: 2%;
  -webkit-transform: scale(0);
          transform: scale(0);
  -webkit-animation: style_grow__1KSH3 0.75s 0s forwards cubic-bezier(0.39, 0.575, 0.565, 1);
          animation: style_grow__1KSH3 0.75s 0s forwards cubic-bezier(0.39, 0.575, 0.565, 1);
}

.style_title__3S2Oa {
  opacity: 0;
  -webkit-animation: style_appear__1Qku9 0.75s 0s forwards cubic-bezier(0.39, 0.575, 0.565, 1);
          animation: style_appear__1Qku9 0.75s 0s forwards cubic-bezier(0.39, 0.575, 0.565, 1);
}

.style_headerContainer__2CWeN::after {
  content: "";
  position: absolute;
  border-radius: 100%;
  z-index: -1;
  display: inline-block;
  background-color: #3cbf0a;
  width: 107px;
  padding-bottom: 107px;
  left: 2.5%;
  top: -70%;
  -webkit-transform: translate(-42%, 45%) scale(0);
          transform: translate(-42%, 45%) scale(0);
  -webkit-animation: style_growAndMove__3WDHO 1s 0.75s forwards cubic-bezier(0.39, 0.575, 0.565, 1);
          animation: style_growAndMove__3WDHO 1s 0.75s forwards cubic-bezier(0.39, 0.575, 0.565, 1);
}

@-webkit-keyframes style_grow__1KSH3 {
  from {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  to {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes style_grow__1KSH3 {
  from {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  to {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@-webkit-keyframes style_appear__1Qku9 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes style_appear__1Qku9 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-webkit-keyframes style_lines__rky8- {
  from {
    -webkit-transform: translateY(9%);
            transform: translateY(9%);
  }
  to {
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
  }
}

@keyframes style_lines__rky8- {
  from {
    -webkit-transform: translateY(9%);
            transform: translateY(9%);
  }
  to {
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
  }
}

@-webkit-keyframes style_growAndMove__3WDHO {
  0% {
    -webkit-transform: translate(-42%, 45%) scale(0);
            transform: translate(-42%, 45%) scale(0);
  }
  50% {
    -webkit-transform: translate(-42%, 45%) scale(1);
            transform: translate(-42%, 45%) scale(1);
  }
  100% {
    -webkit-transform: translate(0%, 0%) scale(1);
            transform: translate(0%, 0%) scale(1);
  }
}

@keyframes style_growAndMove__3WDHO {
  0% {
    -webkit-transform: translate(-42%, 45%) scale(0);
            transform: translate(-42%, 45%) scale(0);
  }
  50% {
    -webkit-transform: translate(-42%, 45%) scale(1);
            transform: translate(-42%, 45%) scale(1);
  }
  100% {
    -webkit-transform: translate(0%, 0%) scale(1);
            transform: translate(0%, 0%) scale(1);
  }
}

@media only screen and (min-width: 760px) {
  .style_container__3N68- {
    margin-bottom: 0;
  }

  .style_headerContainer__2CWeN {
    left: 15%;
  }

  .style_whiteCircle__3_Ve3 {
    width: 200px;
    padding-bottom: 200px;
    top: 25%;
    left: 5%;
  }

  .style_headerContainer__2CWeN::after {
    width: 138px;
    padding-bottom: 138px;
  }
}

@media only screen and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .rightLines,
  .style_whiteCircle__3_Ve3,
  .style_title__3S2Oa,
  .style_headerContainer__2CWeN::after {
    -webkit-animation: none;
            animation: none;
    opacity: 1;
    -webkit-transform: none;
            transform: none;
  }
}

.style_outerContainer__3c4Su {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.style_circleContainer__Ow6pt,
.style_labelContainer__1jCsK {
  display: inline-block;
  vertical-align: middle;
  width: 60%;
  padding: 0.5rem;
}

.style_circleContainer__Ow6pt {
  max-width: 150px;
}

@media only screen and (min-width: 760px) {
  .style_circleContainer__Ow6pt {
    width: 30%;
  }
}

.style_container__2zi1P {
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  position: relative;
}

.style_circle__27zW4 {
  border-radius: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.style_outer__2loHN {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.style_inner__1GLIc,
.style_outer__2loHN {
  -webkit-transform: scale(0);
          transform: scale(0);
  -webkit-transition: -webkit-transform 0.5s cubic-bezier(0.39, 0.575, 0.565, 1);
  transition: -webkit-transform 0.5s cubic-bezier(0.39, 0.575, 0.565, 1);
  transition: transform 0.5s cubic-bezier(0.39, 0.575, 0.565, 1);
  transition: transform 0.5s cubic-bezier(0.39, 0.575, 0.565, 1), -webkit-transform 0.5s cubic-bezier(0.39, 0.575, 0.565, 1);
}

.style_inner__1GLIc {
  position: relative;
  -webkit-transition-delay: 0.5s;
          transition-delay: 0.5s;
}

.style_outer__2loHN.style_onScreen__2hFeq,
.style_inner__1GLIc.style_onScreen__2hFeq {
  -webkit-transform: scale(1);
          transform: scale(1);
}

.style_bar__3yVAf {
  padding: 0.25rem 0;
  margin-bottom: 0.25rem;
}

@media only screen and (min-width: 760px) {
  .style_percentageAndBar__6WV0A {
    display: inline-block;
  }
}

.style_container__1Zm9N {
  min-height: 100px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  position: relative;
}

.style_navContainer__2nYQI {
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  position: absolute;
  left: 0;
}

.style_heading__341rS {
  width: 570px !important;
}

.style_link__pG8FH {
  display: block;
  height: 0.25rem;
  margin-bottom: 0.25rem;
  width: 0.5rem;
}

.style_active__2p2la {
  width: 1rem;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .style_container__1Zm9N {
    display: block;
  }
}

@media only screen and (min-width: 600px) {
  .style_heading__341rS {
    padding-left: 75px;
  }
  .style_link__pG8FH {
    height: 0.4rem;
    margin-bottom: 0.4rem;
    width: 1.25rem;
  }
  .style_active__2p2la {
    width: 1.75rem;
  }
}

.style_content__1h15y {
  border-left: 1px solid black;
  padding-left: 1rem;
  margin-top: 1rem;
  margin-left: 1rem;
}

.style_toolTipContainer__1PO5r {
  position: relative;
  padding-left: 2rem;
  padding-top: 2rem;
}

.style_toolTipArrow__21sIG {
  position: absolute;
  z-index: 5;
  top: 75%;
  width: 19px;
  height: 54px;
  overflow: hidden;
}
.style_toolTipContent__1JeHM {
  padding: 0.25rem 1.5rem;
}

.style_quotation__3EGQT {
  height: 1.5rem;
  line-height: 0.9;
  padding-left: 0.5rem;
  position: relative;
}

.style_quotation__3EGQT::after {
  content: "";
  background-color: currentColor;
  width: 2000px;
  padding-top: 1.5rem;
  position: absolute;
  top: 0;
  left: -1000px;
}

.style_quotationMark__1c7EE {
  max-width: 4rem;
  position: relative;
  z-index: 2;
  height: 100%;
  overflow: hidden;
}

.style_name__V4x32 {
  padding: 0;
}

@media only screen and (min-width: 865px) {
  .style_container__1gohz {
    position: absolute;
    left: 0;
    max-width: 250px;
    display: inline-block;
    padding: 0;
  }

  .style_coloredBar__27mnU {
    height: 1.5rem;
    position: relative;
  }

  .style_toolTipContainer__1PO5r {
    padding-left: 0;
    padding-top: 0;
  }

  .style_toolTipArrow__21sIG {
    top: 50%;
  }

  .style_coloredBar__27mnU::after {
    content: "";
    width: 2000px;
    height: 100%;
    right: 0;
    background-color: currentColor;
    position: absolute;
  }

  .style_quotation__3EGQT::after {
    display: none;
  }

  .style_quotation__3EGQT {
    display: inline-block;
    vertical-align: top;
    position: absolute;
    left: -2.5rem;
    margin-top: 1rem;
    padding-right: 0.5rem;
  }

  .style_content__1h15y {
    display: inline-block;
    vertical-align: top;
    margin-left: 0rem;
  }

  .style_name__V4x32 {
    margin-top: 1rem;
  }
}

@media only screen and (min-width: 760px) {
  .style_container__3gPDn {
    min-height: 290px;
    position: relative;
    clear: both;
    display: table;
    padding: 0 !important;
  }
}

@media only screen and (min-width: 865px) {
  .style_container__3lzyB {
    display: inline-block;
    max-width: 470px;
    float: right;
    padding: 0;
  }
}

.style_container__2hwnB {
  opacity: 0;
  -webkit-transition: all 1s;
  transition: all 1s;
}

.style_container__2hwnB.style_onScreen__1pUQP {
  opacity: 1;
}

.style_container__1tNOx {
  border-left: 1px solid #292a36;
  padding-bottom: 1rem;
  padding-left: 1rem;
}

.style_isCentered__2m0tW {
  border-bottom: 1px solid #292a36;
  border-left: none;
  padding-left: 0;
}

.style_legendContainer__1OT_2 {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
}

.style_legendItem__ZqKfF {
  padding: 0.25rem;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  margin-right: 2rem;
}

.style_legendKey__1xFAd {
  padding: 1.5rem 2rem 0 0;
  margin-right: 1rem;
}

.style_innerBarLegend__21AF9 {
  padding: 0.5rem 2rem 0 0;
}

.style_chartScale__56ORP {
  position: relative;
}

.style_scaleCenter__SDeyF {
  position: absolute;
  text-align: center;
  max-width: 150px;
  height: 100%;
  background-color: white;
  opacity: 1;
  color: #66676e;
  left: 50%;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  -webkit-transform: translate(-50%, 10%);
          transform: translate(-50%, 10%);
  top: -100%;
}

.style_chartArea__2gQcn {
  border-left: 1px dashed #292a36;
  border-right: 1px dashed #292a36;
  position: relative;
}

.style_legendContainer__2UNlr {
  position: relative;
}

.style_legendContainer__2UNlr::before {
  content: "";
  position: absolute;
  border-right: 1px solid #292a36;
  height: 100%;
  width: 50%;
  left: 0;
}

.style_legendContainer__2UNlr::after {
  content: "";
  clear: both;
  display: table;
}

.style_legendItem__JxJuJ {
  position: relative;
  max-width: 45%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding-top: 1rem;
}

.style_legendLabel__IOuPr {
  opacity: 1;
  display: block;
}

.style_leftlegendItem__1YmnO {
  float: left;
  text-align: right;
}

.style_rightlegendItem__vwsZb {
  float: right;
  text-align: left;
}

.style_arrowLeft__2g3t4,
.style_arrowRight__15VPJ {
  padding-top: 0.5rem;
  min-width: 20px;
}

.style_arrowLeft__2g3t4 {
  float: left;
}

.style_arrowRight__15VPJ {
  float: right;
}

.style_arrowContainer__2y7bB {
  display: block;
}

@media only screen and (min-width: 520px) {
  .style_legendItem__JxJuJ {
    -webkit-flex-direction: row;
            flex-direction: row;
    min-height: 50px;
    padding-top: 1rem;
    max-width: 25%;
  }

  .style_leftlegendItem__1YmnO {
    -webkit-flex-direction: row-reverse;
            flex-direction: row-reverse;
    margin-left: 5%;
    text-align: left;
  }

  .style_rightlegendItem__vwsZb {
    margin-right: 5%;
    text-align: right;
  }
}

.style_container__2Kr8R {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
}

.style_halfWidth__38QiK {
  width: 50%;
}

.style_container__2Kr8R::before,
.style_container__2Kr8R::after {
  content: "";
  width: 20%;
  height: 100%;
  top: 0;
  z-index: 1;
  position: absolute;
  border-left: 1px dashed #97979d;
  border-right: 1px dashed #97979d;
}
.style_container__2Kr8R::before {
  left: 20%;
}

.style_container__2Kr8R::after {
  left: 60%;
}

.style_hasLeftBorder__1p5Gr {
  border-left: 1px solid #97979d;
}

.style_arrow__1EDg- {
  height: 50px;
  width: 58px;
  overflow: hidden;
}

.style_right__aRq2H {
  -webkit-transform: scaleX(-1);
          transform: scaleX(-1);
}

.style_barContainer__2fYQ3 {
  position: relative;
  margin-bottom: 1rem;
}

.style_barContainer__2fYQ3::before {
  content: "";
  width: 5000%;
  height: 100%;
  left: -2500%;
  top: 0;
  background-color: #bfe5e5;
  opacity: 0.2;
  z-index: -1;
  position: absolute;
}

.style_bar__3Hc35,
.style_emptyBar__5guaU {
  -webkit-transform-origin: left;
          transform-origin: left;
}

.style_bar__3Hc35 {
  padding: 0.25rem 1px;
  position: relative;
  text-align: right;
  left: 50%;
  min-height: 1rem;
  -webkit-transform: scaleX(0);
          transform: scaleX(0);
  -webkit-transition: -webkit-transform 1s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  transition: -webkit-transform 1s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  transition: transform 1s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  transition: transform 1s cubic-bezier(0.785, 0.135, 0.15, 0.86), -webkit-transform 1s cubic-bezier(0.785, 0.135, 0.15, 0.86);
}

.style_bar__3Hc35.style_onScreen__1VNX6 {
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
}

.style_label__1768Z {
  padding: 0 0.25rem;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  position: absolute;
  top: 0;
  bottom: 0;
  opacity: 0;
  min-width: 30%;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}

.style_isNegative__1GSuv {
  -webkit-transform-origin: right;
          transform-origin: right;
  text-align: left;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
}

.style_isNegative__1GSuv.style_bar__3Hc35 {
  padding-right: 0;
  padding-left: 0;
  margin-left: 1px;
}

.style_internalLabel__1gfCn {
  text-align: right;
}

.style_label__1768Z {
  -webkit-transition: opacity 1s 1.4s cubic-bezier(0.77, 0, 0.175, 1);
  transition: opacity 1s 1.4s cubic-bezier(0.77, 0, 0.175, 1);
  opacity: 0;
}

.style_label__1768Z.style_onScreen__1VNX6 {
  opacity: 1;
}

.style_isNegative__1GSuv.style_label__1768Z {
  text-align: right;
}

.style_offsetLabel__38_QD {
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
}

.style_offsetLabel__38_QD.style_isNegative__1GSuv {
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
}

@media only screen and (max-width: 370px) {
  .style_offsetLabelBabyBoomer__24IwI {
    font-size: 0.7rem;
  }
}

@media only screen and (min-width: 760px) {
  .style_innerScale__3GuD7 {
    opacity: 0.7;
  }

  .style_label__1768Z {
    color: #292a36 !important;
  }

  .style_offsetLabel__38_QD {
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    -webkit-transform: none;
            transform: none;
  }

  .style_offsetLabel__38_QD.style_isNegative__1GSuv {
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    -webkit-transform: none;
            transform: none;
  }
}

.style_innerBar__1xXwh {
  padding-top: 0.5rem;
  padding-right: 1px;
  position: absolute;
  left: 0;
  display: inline-block;
  top: 50%;
  -webkit-transform: scaleX(0) translateY(-50%);
          transform: scaleX(0) translateY(-50%);
  opacity: 0;
  -webkit-transform-origin: left;
          transform-origin: left;
  z-index: 5;
  -webkit-transition: -webkit-transform 1s 1s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  transition: -webkit-transform 1s 1s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  transition: transform 1s 1s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  transition: transform 1s 1s cubic-bezier(0.785, 0.135, 0.15, 0.86), -webkit-transform 1s 1s cubic-bezier(0.785, 0.135, 0.15, 0.86);
}

.style_innerBar__1xXwh.style_onScreen__3BiaJ {
  -webkit-transform: scaleX(1) translateY(-50%);
          transform: scaleX(1) translateY(-50%);
  opacity: 1;
}

.style_outerLabel__4sPSk {
  padding: 0.25rem;
}

.style_chartArea__2SzYP,
.style_chartScale__1kavv {
  position: relative;
}

.style_chartArea__2SzYP {
  border-right: 1px dashed #97979d;
  padding-top: 1rem;
}

.style_outerContainer__PIz0f {
  position: relative;
  margin-top: 4rem;
}

.style_container__3gh_n {
  overflow: visible;
}

.style_controls__2gYtQ {
  position: relative;
  min-height: 2rem;
  width: 80%;
  margin: 0 auto;
}

.style_button__LDPao {
  position: absolute;
  height: 100%;
  width: 50%;
  padding: 0 0.5rem;
  font-size: 14px;
  cursor: pointer;
  border: 1px solid #33c100;
  display: inline-block;
  text-align: center;
  -webkit-transition: all 0.4s cubic-bezier(0.77, 0, 0.175, 1);
  transition: all 0.4s cubic-bezier(0.77, 0, 0.175, 1);
}

.style_button__LDPao:hover {
  color: white;
  background-color: #33c100;
}

.style_button__LDPao,
.style_buttonContent__fG6Ps {
  outline: none;
}

.style_buttonContent__fG6Ps {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

:focus > .style_buttonContent__fG6Ps {
  outline: auto 5px -webkit-focus-ring-color;
}

.style_buttonLeft__3PjR8 {
  right: 50%;
  border-radius: 5px 0 0 5px;
  border-right: none;
}

.style_buttonRight__3uI9M {
  border-left: none;
  left: 50%;
  border-radius: 0 5px 5px 0;
}

.style_arrow__3giqs {
  opacity: 0;
  top: 150%;
  left: 0%;
  position: absolute;
}

.style_rightArrow__1AsC9 {
  left: 87%;
  -webkit-transform: scaleX(-1);
          transform: scaleX(-1);
}

.style_dot__1FyYX {
  opacity: 0;
  fill: #8ad78d;
  -webkit-transition: fill 0.4s cubic-bezier(0.77, 0, 0.175, 1),
    opacity 1s cubic-bezier(0.39, 0.575, 0.565, 1),
    -webkit-transform 1s cubic-bezier(0.39, 0.575, 0.565, 1);
  transition: fill 0.4s cubic-bezier(0.77, 0, 0.175, 1),
    opacity 1s cubic-bezier(0.39, 0.575, 0.565, 1),
    -webkit-transform 1s cubic-bezier(0.39, 0.575, 0.565, 1);
  transition: fill 0.4s cubic-bezier(0.77, 0, 0.175, 1),
    transform 1s cubic-bezier(0.39, 0.575, 0.565, 1),
    opacity 1s cubic-bezier(0.39, 0.575, 0.565, 1);
  transition: fill 0.4s cubic-bezier(0.77, 0, 0.175, 1),
    transform 1s cubic-bezier(0.39, 0.575, 0.565, 1),
    opacity 1s cubic-bezier(0.39, 0.575, 0.565, 1),
    -webkit-transform 1s cubic-bezier(0.39, 0.575, 0.565, 1);
  -webkit-transform: translate(0px, -100px);
          transform: translate(0px, -100px);
}

.style_dot__1FyYX.style_active__2U1HQ {
  fill: #33c100;
}

.style_dot__1FyYX.style_onScreen__37kTM {
  -webkit-transform: translate(0px, 0px);
          transform: translate(0px, 0px);
  opacity: 1;
}

.style_dot__1FyYX:not(.style_loaded__3fQSs) {
  -webkit-transition-delay: 0s !important;
          transition-delay: 0s !important;
}

.style_label__2ad4X {
  opacity: 0;
  font-size: 3.5px;
  -webkit-transition: all 1s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  transition: all 1s cubic-bezier(0.785, 0.135, 0.15, 0.86);
}

.style_label__2ad4X.style_onScreen__37kTM.style_active__2U1HQ.style_loaded__3fQSs {
  -webkit-transition-delay: 2s;
          transition-delay: 2s;
  opacity: 1;
}

.style_label__2ad4X.style_active__2U1HQ:not(.style_loaded__3fQSs) {
  -webkit-transition-delay: 0s;
          transition-delay: 0s;
  opacity: 1;
}

.style_toolTip__l4zlO {
  padding: 0.25rem;
  position: absolute;
  -webkit-transform: translateY(-100%);
          transform: translateY(-100%);
}

@media only screen and (min-width: 760px) {
  .style_arrow__3giqs {
    opacity: 1;
  }

  .style_button__LDPao {
    width: 40%;
    padding: 0.5rem;
    min-height: 2.5rem;
    font-size: 16px;
  }
}

@media only screen and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .style_container__3gh_n {
    min-height: 400px;
  }
}

@media only screen and (min-width: 760px) and (-ms-high-contrast: none),
  (-ms-high-contrast: active) {
  .style_container__3gh_n {
    min-height: 520px;
  }
}

.style_list__1WKKf {
  padding: 0;
  margin-bottom: -0.5rem;
}

.style_container__2GS-d {
  padding-bottom: 1rem;
  display: -webkit-flex;
  display: flex;
  padding: 0;
  margin-bottom: 1rem;
}

.style_iconContainer__1wrl7 {
  width: 20%;
}

.style_textContainer__2-8qW {
  width: 80%;
  padding-left: 1rem;
}

@media only screen and (min-width: 600px) {
  .style_container__2GS-d {
    max-width: 520px;
  }
}

@media only screen and (min-width: 760px) {
  .style_container__2GS-d {
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    display: -webkit-flex;
    display: flex;
    max-width: 792px;
  }
  .style_textContainer__2-8qW {
    max-width: 520px;
  }

  .style_iconContainer__1wrl7 {
    max-width: 120px;
  }
}

/* 1. call it out */
.callItOut {
  -webkit-transform: scale(0);
          transform: scale(0);
  -webkit-transform-origin: center;
          transform-origin: center;
  -webkit-transition: -webkit-transform 1s cubic-bezier(0.23, 1, 0.32, 1);
  transition: -webkit-transform 1s cubic-bezier(0.23, 1, 0.32, 1);
  transition: transform 1s cubic-bezier(0.23, 1, 0.32, 1);
  transition: transform 1s cubic-bezier(0.23, 1, 0.32, 1), -webkit-transform 1s cubic-bezier(0.23, 1, 0.32, 1);
}

.style_onScreen__qBYL_ .callItOut {
  -webkit-transform: scale(1);
          transform: scale(1);
}

/* 2. get a mentor */
.mentor1 {
  -webkit-transform: scale(0.62);
          transform: scale(0.62);
  -webkit-transform-origin: center;
          transform-origin: center;
  -webkit-transition: -webkit-transform 1s cubic-bezier(0.23, 1, 0.32, 1);
  transition: -webkit-transform 1s cubic-bezier(0.23, 1, 0.32, 1);
  transition: transform 1s cubic-bezier(0.23, 1, 0.32, 1);
  transition: transform 1s cubic-bezier(0.23, 1, 0.32, 1), -webkit-transform 1s cubic-bezier(0.23, 1, 0.32, 1);
}

.mentor2 {
  -webkit-transform: translate(-22%, 22%);
          transform: translate(-22%, 22%);
  -webkit-transition: -webkit-transform 1s cubic-bezier(0.23, 1, 0.32, 1);
  transition: -webkit-transform 1s cubic-bezier(0.23, 1, 0.32, 1);
  transition: transform 1s cubic-bezier(0.23, 1, 0.32, 1);
  transition: transform 1s cubic-bezier(0.23, 1, 0.32, 1), -webkit-transform 1s cubic-bezier(0.23, 1, 0.32, 1);
}

/* translate(40%, -2%) */
.style_onScreen__qBYL_ .mentor1 {
  -webkit-transform: scale(1);
          transform: scale(1);
}
.style_onScreen__qBYL_ .mentor2 {
  -webkit-transform: translate(0%, 0%);
          transform: translate(0%, 0%);
}

/* 3. List your achievements */
.achievements2,
.achievements3,
.achievements4,
.achievements5 {
  opacity: 0;
  -webkit-transform: translateY(-17%);
          transform: translateY(-17%);
}

.achievements3 {
  -webkit-animation-delay: 0.25s;
          animation-delay: 0.25s;
}

.achievements4 {
  -webkit-animation-delay: 0.5s;
          animation-delay: 0.5s;
}

.achievements5 {
  -webkit-animation-delay: 0.75s;
          animation-delay: 0.75s;
}

.style_onScreen__qBYL_ .achievements2,
.style_onScreen__qBYL_ .achievements3,
.style_onScreen__qBYL_ .achievements4,
.style_onScreen__qBYL_ .achievements5 {
  -webkit-animation-name: style_appearAndMoveDown__2ue33;
          animation-name: style_appearAndMoveDown__2ue33;
  -webkit-animation-duration: 0.25s;
          animation-duration: 0.25s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
          animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}

@-webkit-keyframes style_appearAndMoveDown__2ue33 {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-17%);
            transform: translateY(-17%);
  }
  50% {
    opacity: 1;
    -webkit-transform: translateY(-17%);
            transform: translateY(-17%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
  }
}

@keyframes style_appearAndMoveDown__2ue33 {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-17%);
            transform: translateY(-17%);
  }
  50% {
    opacity: 1;
    -webkit-transform: translateY(-17%);
            transform: translateY(-17%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
  }
}

/* 4. Change your language */

.language {
  -webkit-transform: translateX(-30%) translateY(-3%);
          transform: translateX(-30%) translateY(-3%);
}

.style_onScreen__qBYL_ .language {
  -webkit-animation: style_moveDownAndAcross__1l1uI 1s forwards cubic-bezier(0.23, 1, 0.32, 1);
          animation: style_moveDownAndAcross__1l1uI 1s forwards cubic-bezier(0.23, 1, 0.32, 1);
}

@-webkit-keyframes style_moveDownAndAcross__1l1uI {
  0% {
    -webkit-transform: translateX(-30%) translateY(-3%);
            transform: translateX(-30%) translateY(-3%);
  }
  90% {
    -webkit-transform: translateX(0%) translateY(-3%);
            transform: translateX(0%) translateY(-3%);
  }
  100% {
    -webkit-transform: translateX(0%) translateY(0%);
            transform: translateX(0%) translateY(0%);
  }
}

@keyframes style_moveDownAndAcross__1l1uI {
  0% {
    -webkit-transform: translateX(-30%) translateY(-3%);
            transform: translateX(-30%) translateY(-3%);
  }
  90% {
    -webkit-transform: translateX(0%) translateY(-3%);
            transform: translateX(0%) translateY(-3%);
  }
  100% {
    -webkit-transform: translateX(0%) translateY(0%);
            transform: translateX(0%) translateY(0%);
  }
}

/* 5. Assess your expectations */

.expectations {
  -webkit-transform: translate(-46%, -44%);
          transform: translate(-46%, -44%);
  -webkit-transition: -webkit-transform 1s cubic-bezier(0.23, 1, 0.32, 1);
  transition: -webkit-transform 1s cubic-bezier(0.23, 1, 0.32, 1);
  transition: transform 1s cubic-bezier(0.23, 1, 0.32, 1);
  transition: transform 1s cubic-bezier(0.23, 1, 0.32, 1), -webkit-transform 1s cubic-bezier(0.23, 1, 0.32, 1);
}

.style_onScreen__qBYL_ .expectations {
  -webkit-transform: translate(0%, 0%);
          transform: translate(0%, 0%);
}

@media only screen and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .style_onScreen__qBYL_ .callItOut,
  .style_onScreen__qBYL_ .mentor1,
  .style_onScreen__qBYL_ .mentor2,
  .style_onScreen__qBYL_ .achievements2,
  .style_onScreen__qBYL_ .achievements3,
  .style_onScreen__qBYL_ .achievements4,
  .style_onScreen__qBYL_ .achievements5,
  .style_onScreen__qBYL_ .language,
  .style_onScreen__qBYL_ .expectations {
    -webkit-animation: none !important;
            animation: none !important;
    opacity: 1;
    -webkit-transform: none;
            transform: none;
  }
}

.style_container__miJ1G {
  padding: 4rem 0;
  background-color: currentColor;
}

.style_container__zH4KU {
  display: inline-block;
  vertical-align: top;
  width: 100%;
}

.style_svgContainer__1tBZf {
  width: 100%;
}

.style_image__1FWAc,
.style_title__3rrip {
  padding-bottom: 1rem;
}

.style_innerContainer__UsCFD {
  padding: 0.5rem;
}

@media only screen and (min-width: 500px) {
  .style_container__zH4KU {
    width: 50%;
  }
  .style_innerContainer__UsCFD {
    padding: 1rem;
  }
}

.style_container__1PfCW {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding: 1rem 0;
}

.style_shadow__2U5WP {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.17);
}

.style_container__1il02 {
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  overflow: hidden;
}

.style_linkContainer__iE2rf {
  display: inline-block;
  height: 30px;
  width: 30px;
  margin-right: 1rem;
}

.style_socialIcon__3rsVC > g > circle {
  fill: #ffffff;
}

.style_socialIcon__3rsVC > g > path,
.style_socialIcon__3rsVC > g > circle {
  -webkit-transition: all 0.4s cubic-bezier(0.77, 0, 0.175, 1);
  transition: all 0.4s cubic-bezier(0.77, 0, 0.175, 1);
}

.style_socialIcon__3rsVC:hover > g > path {
  fill: #ffffff;
}

.style_socialIcon__3rsVC:hover > g > circle {
  stroke: #33c100;
  fill: #33c100;
}

.style_footerTextContainer__8X-5X {
  padding: 1rem 0;
  margin-top: 2rem;
}

.style_socialContainer__3-J6s {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding: 1rem;
}

.style_navContainer__1CQ_J {
  padding-top: 1rem;
}

.style_textAndSocialContainer__2I1o1 {
  border-bottom: 1px solid #97979d;
}

.style_navContainer__1CQ_J > a {
  padding: 0.5rem 1rem 0.5rem 0;
  display: inline-block;
}

@media only screen and (min-width: 760px) {
  .style_footerTextContainer__8X-5X {
    display: inline-block;
    max-width: 60%;
    vertical-align: top;
    float: left;
  }

  .style_textAndSocialContainer__2I1o1::after {
    content: "";
    clear: both;
    display: table;
  }

  .style_socialContainer__3-J6s {
    display: -webkit-inline-flex;
    display: inline-flex;
    float: right;
    margin-top: 2rem;
  }
}

