body {
  margin: 0;
  padding: 0;
  font-family: "Verdana", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

#root {
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
a {
  margin: 0;
  text-decoration: none;
}

/* Makes images responsive by default */
img,
svg {
  display: inline-block;
  vertical-align: bottom;
  max-width: 100%;
}

.extraLargeSpacingPlus {
  margin-bottom: 7rem;
}

.extraLargeSpacing {
  margin-bottom: 6rem;
}

.largeSpacing {
  margin-bottom: 4rem;
}

.smallSpacing {
  margin-bottom: 2rem;
}

@media only screen and (min-width: 760px) {
  .extraLargeSpacingPlus {
    margin-bottom: 6rem;
  }
}
