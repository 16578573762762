.outerContainer {
  display: flex;
  justify-content: space-between;
}

.circleContainer,
.labelContainer {
  display: inline-block;
  vertical-align: middle;
  width: 60%;
  padding: 0.5rem;
}

.circleContainer {
  max-width: 150px;
}

@media only screen and (min-width: 760px) {
  .circleContainer {
    width: 30%;
  }
}
