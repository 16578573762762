.container {
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  position: relative;
}

.circle {
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.outer {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.inner,
.outer {
  transform: scale(0);
  transition: transform 0.5s cubic-bezier(0.39, 0.575, 0.565, 1);
}

.inner {
  position: relative;
  transition-delay: 0.5s;
}

.outer.onScreen,
.inner.onScreen {
  transform: scale(1);
}
