.container {
  position: relative;
  min-height: 350px;
  margin-top: -0.25rem;
  margin-bottom: 1rem;
  z-index: -1;
}

.svg {
  position: absolute;
  overflow: visible;
  left: -5350px;
}

:global(.rightLines) {
  transform: translateY(9%);
  animation: lines 1.25s 0.5s forwards cubic-bezier(0.39, 0.575, 0.565, 1);
}

.headerContainer {
  position: absolute;
  mix-blend-mode: multiply;
  width: 300px;
  top: 50%;
  left: 17%;
  transform: translateY(-50%);
}

.whiteCircle {
  position: absolute;
  background-color: white;
  width: 155px;
  padding-bottom: 155px;
  position: absolute;
  border-radius: 100%;
  z-index: 0;
  top: 30%;
  left: 2%;
  transform: scale(0);
  animation: grow 0.75s 0s forwards cubic-bezier(0.39, 0.575, 0.565, 1);
}

.title {
  opacity: 0;
  animation: appear 0.75s 0s forwards cubic-bezier(0.39, 0.575, 0.565, 1);
}

.headerContainer::after {
  content: "";
  position: absolute;
  border-radius: 100%;
  z-index: -1;
  display: inline-block;
  background-color: #3cbf0a;
  width: 107px;
  padding-bottom: 107px;
  left: 2.5%;
  top: -70%;
  transform: translate(-42%, 45%) scale(0);
  animation: growAndMove 1s 0.75s forwards cubic-bezier(0.39, 0.575, 0.565, 1);
}

@keyframes grow {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

@keyframes appear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes lines {
  from {
    transform: translateY(9%);
  }
  to {
    transform: translateY(0%);
  }
}

@keyframes growAndMove {
  0% {
    transform: translate(-42%, 45%) scale(0);
  }
  50% {
    transform: translate(-42%, 45%) scale(1);
  }
  100% {
    transform: translate(0%, 0%) scale(1);
  }
}

@media only screen and (min-width: 760px) {
  .container {
    margin-bottom: 0;
  }

  .headerContainer {
    left: 15%;
  }

  .whiteCircle {
    width: 200px;
    padding-bottom: 200px;
    top: 25%;
    left: 5%;
  }

  .headerContainer::after {
    width: 138px;
    padding-bottom: 138px;
  }
}

@media only screen and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  :global(.rightLines),
  .whiteCircle,
  .title,
  .headerContainer::after {
    animation: none;
    opacity: 1;
    transform: none;
  }
}
