.container {
  min-height: 100px;
  display: flex;
  align-items: center;
  position: relative;
}

.navContainer {
  display: inline-flex;
  flex-direction: column;
  position: absolute;
  left: 0;
}

.heading {
  width: 570px !important;
}

.link {
  display: block;
  height: 0.25rem;
  margin-bottom: 0.25rem;
  width: 0.5rem;
}

.active {
  width: 1rem;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .container {
    display: block;
  }
}

@media only screen and (min-width: 600px) {
  .heading {
    padding-left: 75px;
  }
  .link {
    height: 0.4rem;
    margin-bottom: 0.4rem;
    width: 1.25rem;
  }
  .active {
    width: 1.75rem;
  }
}
