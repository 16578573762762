.pageTitle {
  font-size: 33px;
  line-height: 1.3;
  letter-spacing: 0.15rem;
  font-weight: bold;
}

.sectionHeader,
.relatedSubtitle {
  font-size: 20px;
}

.relatedSubtitle {
  line-height: 1.2;
  letter-spacing: -0.42px;
}

.relatedDescription {
  line-height: 1.4;
  letter-spacing: -0.23px;
}

.subtitle {
  font-size: 18px;
}

.subtitle,
.sectionHeader {
  line-height: 1.6;
}

.body,
.listHeader,
.relatedDescription {
  font-size: 16px;
  padding-bottom: 0.5rem;
}

.body,
.listHeader {
  line-height: 2;
}

.quote {
  line-height: 1.6;
}

.small {
  font-size: 14px;
}

.chartLabel {
  font-size: 14px;
}

.small {
  line-height: 1.9;
}

.toolTip,
.chartScale,
.copyright {
  font-size: 12px;
}

.copyright {
  margin-bottom: 4rem;
  padding: 1rem 0;
  display: inline-block;
}

.hightlightContainer {
  white-space: pre-wrap;
  margin-bottom: 0.75rem;
}

.highlight {
  padding-right: 0.75rem;
  padding-bottom: 0;
}

.largeStat {
  font-size: 40px;
  font-weight: bold;
}

.chartScale {
  opacity: 1;
}

.isCentered {
  text-align: center;
}

.listHeader,
.subtitle {
  font-weight: bold;
}

.underline {
  position: relative;
  padding-bottom: 0.2rem;
  border-bottom: 2px solid #33c100;
  transition: padding-bottom 0.3s ease;
}

.underline:hover {
  padding-bottom: 0;
}

.underlineSubtitle {
  text-decoration: underline;
}

.innerScale {
  text-align: center;
  position: absolute;
  transform: translateX(-50%);
  opacity: 0;
  transition: all 0.4s;
}

.outerScale {
  position: absolute;
  right: 0;
  top: 100;
}

@media only screen and (min-width: 760px) {
  .pageTitle {
    font-size: 39px;
  }
  .noPadding {
    padding: 0;
    margin-bottom: 0;
  }

  .sectionHeader {
    font-size: 34px;
    line-height: 1.6;
  }

  .subtitle {
    font-size: 20px;
  }

  .body,
  .listHeader {
    font-size: 18px;
  }

  .chartLabel {
    font-size: 16px;
  }

  .toolTip,
  .chartScale {
    font-size: 14px;
  }

  .largeStat {
    font-size: 48px;
  }

  .innerScale {
    opacity: 1;
  }
}
