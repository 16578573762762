.outerContainer {
  position: relative;
  margin-top: 4rem;
}

.container {
  overflow: visible;
}

.controls {
  position: relative;
  min-height: 2rem;
  width: 80%;
  margin: 0 auto;
}

.button {
  position: absolute;
  height: 100%;
  width: 50%;
  padding: 0 0.5rem;
  font-size: 14px;
  cursor: pointer;
  border: 1px solid #33c100;
  display: inline-block;
  text-align: center;
  transition: all 0.4s cubic-bezier(0.77, 0, 0.175, 1);
}

.button:hover {
  color: white;
  background-color: #33c100;
}

.button,
.buttonContent {
  outline: none;
}

.buttonContent {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

:focus > .buttonContent {
  outline: auto 5px -webkit-focus-ring-color;
}

.buttonLeft {
  right: 50%;
  border-radius: 5px 0 0 5px;
  border-right: none;
}

.buttonRight {
  border-left: none;
  left: 50%;
  border-radius: 0 5px 5px 0;
}

.arrow {
  opacity: 0;
  top: 150%;
  left: 0%;
  position: absolute;
}

.rightArrow {
  left: 87%;
  transform: scaleX(-1);
}

.dot {
  opacity: 0;
  fill: #8ad78d;
  transition: fill 0.4s cubic-bezier(0.77, 0, 0.175, 1),
    transform 1s cubic-bezier(0.39, 0.575, 0.565, 1),
    opacity 1s cubic-bezier(0.39, 0.575, 0.565, 1);
  transform: translate(0px, -100px);
}

.dot.active {
  fill: #33c100;
}

.dot.onScreen {
  transform: translate(0px, 0px);
  opacity: 1;
}

.dot:not(.loaded) {
  transition-delay: 0s !important;
}

.label {
  opacity: 0;
  font-size: 3.5px;
  transition: all 1s cubic-bezier(0.785, 0.135, 0.15, 0.86);
}

.label.onScreen.active.loaded {
  transition-delay: 2s;
  opacity: 1;
}

.label.active:not(.loaded) {
  transition-delay: 0s;
  opacity: 1;
}

.toolTip {
  padding: 0.25rem;
  position: absolute;
  transform: translateY(-100%);
}

@media only screen and (min-width: 760px) {
  .arrow {
    opacity: 1;
  }

  .button {
    width: 40%;
    padding: 0.5rem;
    min-height: 2.5rem;
    font-size: 16px;
  }
}

@media only screen and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .container {
    min-height: 400px;
  }
}

@media only screen and (min-width: 760px) and (-ms-high-contrast: none),
  (-ms-high-contrast: active) {
  .container {
    min-height: 520px;
  }
}
