.bar {
  padding: 0.25rem 0;
  margin-bottom: 0.25rem;
}

@media only screen and (min-width: 760px) {
  .percentageAndBar {
    display: inline-block;
  }
}
