/* --- WHITE --- */
.txtWhite {
  color: #ffffff;
}
.bgWhite {
  background-color: #ffffff;
}

/* --- BLACK --- */
.txtBlack {
  color: #292a36;
}
.bgBlack {
  background-color: #292a36;
}

/* --- DARK GREY --- */
.txtDarkGrey {
  color: #97979d;
}
.bgDarkGrey {
  background-color: #97979d;
}

/* --- LIGHT GREY --- */
.txtLightGrey {
  color: #6c6d79;
}
.bgLightGrey {
  background-color: #6c6d79;
}

/* --- GREEN --- */
.txtGreen {
  color: #33c100;
}
.bgGreen {
  background-color: #33c100 !important;
}

/* --- DARK GREEN --- */
.txtDarkGreen {
  color: #2c8f07;
}
.bgDarkGreen {
  background-color: #2c8f07 !important;
}

/* --- LIGHT BLUE --- */
.txtLightBlue {
  color: #bfe5e5;
}

.bgLightBlue {
  background-color: #bfe5e5;
}

/* NOTE: First background color is for IE and Edge, overwritten by second for all other browsers */
.bgLightBlueWithOpacity {
  color: #bfe5e5;
  color: rgba(191, 229, 229, 0.3);
}

.fillLightBlueWithOpacity {
  fill: #bfe5e5;
  opacity: 0.3;
}

/* --- TEAL --- */
.txtTeal {
  color: #008b8b;
}

.bgTeal {
  background-color: #008b8b;
}

/* --- ORANGE --- */
.txtOrange {
  color: #f06c30;
}

.bgOrange {
  background-color: #f06c30;
}

/* --- PINK --- */
.txtPink {
  color: #e2c3d3;
}

.bgPink {
  background-color: #e2c3d3;
}

/* --- MAROON --- */
.txtMaroon {
  color: #8c0e51;
}

.bgMaroon {
  background-color: #8c0e51;
}
