.chartScale {
  position: relative;
}

.scaleCenter {
  position: absolute;
  text-align: center;
  max-width: 150px;
  height: 100%;
  background-color: white;
  opacity: 1;
  color: #66676e;
  left: 50%;
  transition: all 0.4s;
  transform: translate(-50%, 10%);
  top: -100%;
}

.chartArea {
  border-left: 1px dashed #292a36;
  border-right: 1px dashed #292a36;
  position: relative;
}

.legendContainer {
  position: relative;
}

.legendContainer::before {
  content: "";
  position: absolute;
  border-right: 1px solid #292a36;
  height: 100%;
  width: 50%;
  left: 0;
}

.legendContainer::after {
  content: "";
  clear: both;
  display: table;
}

.legendItem {
  position: relative;
  max-width: 45%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding-top: 1rem;
}

.legendLabel {
  opacity: 1;
  display: block;
}

.leftlegendItem {
  float: left;
  text-align: right;
}

.rightlegendItem {
  float: right;
  text-align: left;
}

.arrowLeft,
.arrowRight {
  padding-top: 0.5rem;
  min-width: 20px;
}

.arrowLeft {
  float: left;
}

.arrowRight {
  float: right;
}

.arrowContainer {
  display: block;
}

@media only screen and (min-width: 520px) {
  .legendItem {
    flex-direction: row;
    min-height: 50px;
    padding-top: 1rem;
    max-width: 25%;
  }

  .leftlegendItem {
    flex-direction: row-reverse;
    margin-left: 5%;
    text-align: left;
  }

  .rightlegendItem {
    margin-right: 5%;
    text-align: right;
  }
}
