.barContainer {
  position: relative;
  margin-bottom: 1rem;
}

.barContainer::before {
  content: "";
  width: 5000%;
  height: 100%;
  left: -2500%;
  top: 0;
  background-color: #bfe5e5;
  opacity: 0.2;
  z-index: -1;
  position: absolute;
}

.bar,
.emptyBar {
  transform-origin: left;
}

.bar {
  padding: 0.25rem 1px;
  position: relative;
  text-align: right;
  left: 50%;
  min-height: 1rem;
  transform: scaleX(0);
  transition: transform 1s cubic-bezier(0.785, 0.135, 0.15, 0.86);
}

.bar.onScreen {
  transform: scaleX(1);
}

.label {
  padding: 0 0.25rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  top: 0;
  bottom: 0;
  opacity: 0;
  min-width: 30%;
  backface-visibility: hidden;
}

.isNegative {
  transform-origin: right;
  text-align: left;
  justify-content: flex-end;
}

.isNegative.bar {
  padding-right: 0;
  padding-left: 0;
  margin-left: 1px;
}

.internalLabel {
  text-align: right;
}

.label {
  transition: opacity 1s 1.4s cubic-bezier(0.77, 0, 0.175, 1);
  opacity: 0;
}

.label.onScreen {
  opacity: 1;
}

.isNegative.label {
  text-align: right;
}

.offsetLabel {
  transform: translateX(-100%);
  justify-content: flex-end;
}

.offsetLabel.isNegative {
  transform: translateX(100%);
  justify-content: flex-start;
}

@media only screen and (max-width: 370px) {
  .offsetLabelBabyBoomer {
    font-size: 0.7rem;
  }
}

@media only screen and (min-width: 760px) {
  .innerScale {
    opacity: 0.7;
  }

  .label {
    color: #292a36 !important;
  }

  .offsetLabel {
    justify-content: flex-start;
    transform: none;
  }

  .offsetLabel.isNegative {
    justify-content: flex-end;
    transform: none;
  }
}
