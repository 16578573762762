.container {
  display: inline-block;
  vertical-align: top;
  width: 100%;
}

.svgContainer {
  width: 100%;
}

.image,
.title {
  padding-bottom: 1rem;
}

.innerContainer {
  padding: 0.5rem;
}

@media only screen and (min-width: 500px) {
  .container {
    width: 50%;
  }
  .innerContainer {
    padding: 1rem;
  }
}
