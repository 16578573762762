.container {
  padding-bottom: 1rem;
  display: flex;
  padding: 0;
  margin-bottom: 1rem;
}

.iconContainer {
  width: 20%;
}

.textContainer {
  width: 80%;
  padding-left: 1rem;
}

@media only screen and (min-width: 600px) {
  .container {
    max-width: 520px;
  }
}

@media only screen and (min-width: 760px) {
  .container {
    justify-content: flex-end;
    display: flex;
    max-width: 792px;
  }
  .textContainer {
    max-width: 520px;
  }

  .iconContainer {
    max-width: 120px;
  }
}
