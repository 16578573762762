.container {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.linkContainer {
  display: inline-block;
  height: 30px;
  width: 30px;
  margin-right: 1rem;
}

.socialIcon > g > circle {
  fill: #ffffff;
}

.socialIcon > g > path,
.socialIcon > g > circle {
  transition: all 0.4s cubic-bezier(0.77, 0, 0.175, 1);
}

.socialIcon:hover > g > path {
  fill: #ffffff;
}

.socialIcon:hover > g > circle {
  stroke: #33c100;
  fill: #33c100;
}
