.legendContainer {
  display: flex;
  justify-content: flex-start;
}

.legendItem {
  padding: 0.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 2rem;
}

.legendKey {
  padding: 1.5rem 2rem 0 0;
  margin-right: 1rem;
}

.innerBarLegend {
  padding: 0.5rem 2rem 0 0;
}
